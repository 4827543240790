<template>
  <div>
    <h3>{{ $t('reportcash.title') }}</h3>
    <br />
    <v-divider></v-divider> <br />
    <v-card outlined rounded>
      <v-card-title>
        {{ $t('all.find') }}
        <v-menu
          v-model="mdte1"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          full-width
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="dte1"
              :label="$t('all.from')"
              prepend-icon="fa-calendar"
              readonly
              v-on="on"
              @blur="date1 = parseDate(dte1)"
            ></v-text-field>
          </template>
          <v-date-picker v-model="date1" @input="mdte1 = false" locale="ru-ru"></v-date-picker>
        </v-menu>

        <v-menu
          v-model="mdte2"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          full-width
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="dte2"
              :label="$t('all.to')"
              prepend-icon="fa-calendar"
              readonly
              v-on="on"
              @blur="date2 = parseDate(dte2)"
            ></v-text-field>
          </template>
          <v-date-picker v-model="date2" @input="mdte2 = false" locale="ru-ru"></v-date-picker>
        </v-menu>
        <v-spacer></v-spacer>
        <v-btn small color="primary" @click="dataLoad()">
          {{ $t('all.showreport') }}
        </v-btn>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="tableData"
        :items-per-page="1000"
        hide-default-footer
        class="elevation-1"
      ></v-data-table>
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      headers: [
        { value: 'dte', text: 'Дата' },
        { value: 'job_count', text: 'Заказов' },
        { value: 'job_cost', text: 'Сумма заказов' },
        { value: 'sum_tpe1', text: 'Поступлений нал' },
        { value: 'sum_tpe2', text: 'Поступлений карта' },
      ],
      tableLoad: false,
      tableData: ['load'],
      dteformat: 'DD.MM.YYYY',
      dte1: '',
      dte2: '',
      flgtrue: true,
    }
  },
  computed: {},
  components: {},
  mounted: function() {
    var dt = new Date()
    var month = dt.getMonth() + 1
    var day = dt.getDate()
    var year = dt.getFullYear()
    this.dte2 = day + '.' + month + '.' + year
    this.dte1 = '01.' + month + '.' + year
    //        this.dataLoad()
  },
  methods: {
    dteUpd1(v) {
      this.dte1 = v
    },
    dteUpd2(v) {
      this.dte2 = v
    },
    dataLoad: function() {
      var t = this
      t.$store.commit('loadingStartShow', true)
      this.$http
        .post(this.$store.state.apiUrl + 'reportcash', {
          dte_start: this.dte1,
          dte_finish: this.dte2,
        })
        .then(
          response => {
            t.$store.commit('loadingStartShow', false)
            if (response.body.err > 0) {
              t.tableData = ['error']
            } else {
              t.tableData = response.body
            }
          },
          err => {
            console.log(err)
          },
        )
    },
  },
}
</script>

<style></style>
